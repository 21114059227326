<template>
  <div>
    <div class="wrapper main">
      <div class="left">
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right" class="bread">
          <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
          <el-breadcrumb-item>Order</el-breadcrumb-item>
        </el-breadcrumb> -->
        <div class="category-title">{{ $t('list.category') }}</div>
        <div class="leftbox">
          <div class="title">{{ $t('list.ordertype') }}</div>
          <el-radio-group v-model="checkedOrderType">
            <el-radio :label="0" style="margin-bottom: 10px;" @click.native.prevent="cancel(0)">In Stock</el-radio> <br>
            <el-radio :label="1" style="margin-bottom: 10px;" @click.native.prevent="cancel(1)">Pre-Order</el-radio> <br>
            <el-radio :label="2" @click.native.prevent="cancel(2)">Group Buying</el-radio> <br>
          </el-radio-group>
        </div>
        <div class="leftbox">
          <div class="title">Tag</div>
          <el-radio-group v-model="tag">
            <el-radio :label="1" style="margin-bottom: 10px;" @click.native.prevent="cancel1(1)">Sale</el-radio> <br>
            <el-radio :label="2" style="margin-bottom: 10px;" @click.native.prevent="cancel1(2)">New</el-radio> <br>
            <el-radio :label="3" @click.native.prevent="cancel1(3)">Hot</el-radio> <br>
          </el-radio-group>
        </div>

        <div class="leftbox">
          <div class="title">{{ $t('list.brands') }}</div>
          <el-checkbox-group v-if="this.$store.getters.getUser" v-model="brandCheckedList" @change="getGoodsList">
            <el-checkbox v-for="(brand, index) in brands" :key="index" class="brand-item"
              :label="brand.brand"></el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-else v-model="brandCheckedList" @change="getGoodsList">
            <el-checkbox v-for="(brands1, index) in brands1" :key="index" class="brand-item"
              :label="brands1.brand"></el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="leftbox">
          <el-checkbox-group v-model="categoryCheckedList" @change="getGoodsList">
            <div v-for="category in categories" :key="category.id" style="margin-bottom: 10px;">
              <div style="font-size: 15px; font-weight: bolder; margin-bottom: 10px;">{{ category.categoryName }}</div>
              <el-checkbox v-for="subCategory in category.children" :key="subCategory.id" class="category-item"
                :label="subCategory.id">{{ subCategory.categoryName }}</el-checkbox>
            </div>
          </el-checkbox-group>
        </div>
        <div class="leftbox">
          <div class="title">Price Ranage</div>
          <span> $ </span>
          <el-input v-model="minAmount" class="amount-input" size="mini" placeholder="1"></el-input>
          <span> - </span>
          <el-input v-model="maxAmount" class="amount-input" size="mini" placeholder="1000"></el-input>
        </div>
      </div>

      <div class="right">
        <el-row style="margin-bottom: 10px;">
          <el-col :span="2">
            <div>
              <img src="../assets/imgs/uploads/goods-left.png" style="width: 37px;height: 37px;" alt="">
              <router-link to="/goods">
                <img src="../assets/imgs/uploads/goods-right.png" style="width: 37px;height: 37px;" alt="">
              </router-link>
            </div>
          </el-col>
          <el-col :span="11" style="padding-left: 16px;">
            <el-select v-model="sortType" placeholder="Sort by...">
              <el-option v-for="item in sortList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <span style="margin-left: 15px;color: #333;">
              Display<span style="font-weight: bold;margin: 0 5px;">{{ total }}</span>results
            </span>
          </el-col>
          <el-col :span="8" :offset="3">
            <el-input placeholder="Search..." v-model="keyword" suffix-icon="el-icon-search"
              @keyup.enter.native="handleSearch">
            </el-input>
          </el-col>
        </el-row>
        <div>
          <el-row v-if="loading" style="text-align: center">
            <el-col :span="24"><img src="../assets/imgs/uploads/Animation.gif" alt=""></el-col>
          </el-row>
          <el-row v-else-if="shoppingCart.length > 0" style="text-align: center;">
            <el-col :span="24">
              <div class="list" v-for="(item, index) in shoppingCart" :key="index">
                <div v-if="item.isBuy == true && item.tag !== 0" class="Purchased">PURCHASED</div>
                <div v-if="item.isBuy == true && item.tag == 0" class="Purchased"
                  style="left: 0;padding-left: 0;width: 89px;">PURCHASED</div>
                <div v-if="item.tag == 1" class="off">{{ item.discount * 100 }}% OFF
                </div>
                <div v-if="item.tag == 2" class="NEW">NEW</div>
                <div v-if="item.tag == 3" class="NEW" style="background-color: #FFC700;">HOT</div>
                <div style="width: 400px;">
                  <router-link :to="{ path: '/quick/details', query: { spuId: item.spuId } }"
                    style="display: flex;align-items: center;">
                    <img v-lazy="item.imageUrl" alt="" style="width: 100px;height: 100px;">
                    <div style="font-weight: bold;margin-left: 15px;">
                      <div style="text-align: left;">
                        <div style="display: flex;">
                          {{ item.brand }}
                          <div style="width: 65px;height: 20px;line-height: 20px;font-size: 10px;border: 1px solid #333;
                                    border-radius: 9.5px;text-align: center;margin-left: 10px;">
                            {{ getLabelForStatus(item.proType) }}
                          </div>
                        </div>
                      </div>
                      <div
                        style="text-align: left;width: 275px;overflow: hidden;text-overflow:ellipsis;white-space:nowrap;">
                        {{ item.skuName }}</div>
                    </div>
                  </router-link>
                </div>
                <!-- <div style="width: 64px;">
                  <div class="listtit">Qty in stock</div>
                  <div style="color: #333;margin-top: 26px;">{{ item.stockNum }}</div>
                </div> -->
                <el-divider direction="vertical"></el-divider>
                <div style="width: 117px;">
                  <div class="listtit">
                    <el-tooltip placement="top-end" effect="light">
                      <div slot="content" style="font-size: 12px;color: #545454;">
                        In the context of consolidation,<br /> 'yes' means that items can be sold<br />individually (by
                        piece), while 'no'<br /> means that items must be sold in <br />whole cases (by box).
                      </div>
                      <el-button class="listtit" style="width: 118px;">MOQ / Sales Unit</el-button>
                    </el-tooltip>
                  </div>
                  <div style="color: #333;margin-top: 26px;">{{ item.amount }} / {{ item.isLcl === 0 ? 'carton' :
                    'piece' }}</div>
                </div>
                <el-divider direction="vertical"></el-divider>
                <div style="width: 57px;">
                  <div class="listtit">{{ $t('table.price') }}</div>
                  <div style="color: #333;margin-top: 26px;">${{ item.price }}</div>
                </div>
                <el-divider direction="vertical"></el-divider>
                <div style="width: 110px;">
                  <div class="listtit">Quantity</div>
                  <div style="margin-top: 26px;">
                    <el-input-number v-if="item.isLcl === 0" style="width: 110px;" :step="item.amount" size="mini"
                      :value="item.memberNum ? item.memberNum : 0" @change="handleChange($event, index, item.skuId)"
                      :min="0" step-strictly></el-input-number>

                    <el-input-number v-else style="width: 110px;" size="mini" :value="item.memberNum ? item.memberNum : 0"
                      @change="handleChange($event, index, item.skuId)" :min="0"></el-input-number>
                  </div>
                </div>
                <el-divider direction="vertical"></el-divider>
                <div style="width: 57px;">
                  <div class="listtit">Total</div>
                  <div style="color: #333;margin-top: 26px;">${{ formatF((item.price * 1000) * item.memberNum / 1000) }}
                  </div>
                </div>
                <el-divider direction="vertical"></el-divider>
                <div>
                  <router-link :to="{ path: '/quick/details', query: { spuId: item.spuId } }" class="more">More Info<i
                      class="el-icon-arrow-right" style="font-weight: bold;font-size: 12px;"></i></router-link>
                </div>
                <!-- <div
                  style="width: 65px;height: 20px;line-height: 20px;font-size: 10px;border: 1px solid #333;border-radius: 9.5px;">
                  {{ getLabelForStatus(item.proType) }}
                </div> -->
              </div>
            </el-col>
            <!-- <el-col :span="24">
              <el-pagination layout="prev, pager, next,jumper" :current-page="currentPage" :page-size="pageSize" :total="total" :pager-count="5"
                background @current-change="currentChange"></el-pagination>
            </el-col> -->
          </el-row>
          <el-row v-else style="text-align: center">
            <el-col :span="24" style="font-size: 40px;font-weight: bold;color: #959595;">Sorry...</el-col>
            <el-col :span="24" style="font-size: 40px;font-weight: bold;color: #959595;">No relevant products were
              found</el-col>
            <el-col :span="24" style="font-size: 18px;color: #959595;">Please look at other
              products.</el-col>
            <el-col :span="24"><img style="width: 339px; height: 350px;" src="../assets/imgs/cart-empty.png"
                alt=""></el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  metaInfo: {
    meta: [
      {
        name: 'description',
        content: 'Streamline your shopping experience with our quick order page. Easily place orders for your favorite cat and dog toys, supplies, and accessories.Simplify your pet product purchases with our fast order process. Browse, select, and checkout in minutes for all your pets needs.Enjoy hassle-free shopping for your furry friends with our quick checkout page. Find everything you need and complete your purchase swiftly.Speed up your pet toy shopping with our convenient quick order page. Purchase toys, treats, and essentials for your pets without any delays.Optimize your shopping journey for pet supplies with our efficient quick order platform. Get your orders placed and processed in no time.'
      },
      {
        name: 'keywords',
        content: 'Quick order,Pet supplies,Fast checkout,Pet accessories,Convenient shopping,Efficient order process,Pet toy orders,Streamlined shopping,Pet essentials,Hassle-free purchases,',
      }
    ],
  },
  data() {
    return {
      categoryList: [], //分类列表
      categoryID: [], // 分类id
      product: "", // 商品列表
      productList: "",
      total: 0, // 商品总量
      pageSize: 10, // 每页显示的商品数量
      currentPage: 1, //当前页码
      activeName: "-1", // 分类列表当前选中的id
      search: "", // 搜索条件
      brands: [],
      brands1: [
        { brand: 'Gifts For Paws' },
        { brand: 'Gigwi' },
        { brand: 'PETGEEK' },
        { brand: 'SKIPDAWG' }
      ],
      selectedIndex: null,
      categories: '',
      messages: [],
      messages1: [],
      clicks: '',
      highlightedIndex: null, // 选中的文字索引,
      checkedOrderType: 0,
      brandCheckedList: [],
      brandCheckedList1: [],
      categoryCheckedList: [],
      minAmount: '',
      maxAmount: '',
      sortList: [
        { value: 'Best Sellers', label: 'Best Sellers' },
        { value: 'Newest Arrivals', label: 'Newest Arrivals' },
        { value: 'Price Low to High', label: 'Price Low to High' },
        { value: 'Price High to Low', label: 'Price High to Low' },
        { value: 'Default', label: 'Default' },
      ],
      sortType: '',
      keyword: '',
      goods: [],
      shoppingCart: [],
      isAllCheck: false,
      loading: true,
      tag: '',
    };
  },
  activated() {
    this.getBrands()
  },
  watch: {

  },
  methods: {
    ...mapActions(["setShoppingCart", "deleteShoppingCart",]),
    handleSearch() {
      this.loading = true;
      this.shoppingCart = []
      this.getGoodsList()
    },
    getBrands() {
      this.$axios
        .post("/api/mall/member/getUserBrands", {
          id: this.$store.getters.getUser.id,
        })
        .then(res => {
          this.brands = res.data.data
          this.brandCheckedList1 = this.brands.map(item => item.brand)
          this.getGoodsList()
        })
        .catch(err => {
          return Promise.reject(err);
        });
    },
    getGoodsList: function () {
      this.brandCheckedList1 = this.brandCheckedList
      this.$axios.post("/api/mall/cart/list", {
        pageNum: this.keyword === '' ? this.currentPage : 1,
        pageSize: this.pageSize,
        proType: this.checkedOrderType,
        tag: this.tag,
        memberId: this.$store.getters.getUser.id,
        brands: this.brandCheckedList1,
        categoryIds: this.categoryCheckedList,
        sortType: this.sortType,
        keyword: this.keyword
      }).then(res => {
        this.shoppingCart = res.data.data;
        this.total = res.data.data ? res.data.data.length : 0;
        this.loading = false;
        window.scrollTo(0, 0);
      }).catch(err => {
        this.busy = false;
        return Promise.reject(err);
      });
    },
    getTypeData(val) {
      this.$axios
        .post("/api/mall/cart/list", {
          // pageNum: this.currentPage,
          // pageSize: this.pageSize,
          proType: val
        })
        .then(res => {
          this.product = res.data.data.records;
          this.total = res.data.data.total;
        })
        .catch(err => {
          return Promise.reject(err);
        });
      this.highlightedIndex = val
    },
    currentChange(currentPage) {
      this.currentPage = currentPage;
      this.getGoodsList()
    },
    handleChange(currentVal, key, uid) {
      this.$axios
        .post("/api/mall/cart/updateNum", {
          memberId: this.$store.getters.getUser.id,
          skuId: uid,
          num: currentVal
        }).then(res => {
          if (res.data.code === 200) {
            this.shoppingCart[key].memberNum = currentVal
            this.$axios
              .post("/api/mall/cart/" + this.$store.getters.getUser.id)
              .then(res => {
                if (res.data.code === 200) {
                  // 001 为成功, 更新vuex购物车状态
                  this.setShoppingCart(res.data.data);
                } else {
                  // 提示失败信息
                  this.notifyError(res.data.message);
                }
              })
              .catch(err => {
                return Promise.reject(err);
              });
          }
        })
    },

    getfenlei() {
      this.$axios
        .post("/api/mall/category/all")
        .then(res => {
          this.categories = res.data.data
        })
        .catch(err => {
          return Promise.reject(err);
        });
    },
    getData() {
      this.$axios
        .post("/api/mall/cart/list", {
          pageNum: this.currentPage,
          pageSize: this.pageSize
        })
        .then(res => {
          this.product = res.data.data.records;
          this.total = res.data.data.total;
        })
        .catch(err => {
          return Promise.reject(err);
        });


    },
    // 通过搜索条件向后端请求商品数据
    getProductBySearch() {
      this.$axios
        .post("/api/mall/cart/list", {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          name: this.search
        })
        .then(res => {
          this.product = res.data.data.records;
          this.total = res.data.data.total;
        })
        .catch(err => {
          return Promise.reject(err);
        });
    },
    selectbrands(index) {
      this.selectedIndex = index === this.selectedIndex ? null : index;
    },
    getLabelForStatus(status) {
      switch (status) {
        case 0:
          return this.$t('list.instock');
        case 1:
          return this.$t('list.production');
        case 2:
          return this.$t('list.ordertogether');
        default:
          return 'unknown';
      }
    },
    formatF(value) {
      let value1 = Math.round(parseFloat(value) * 100) / 100;
      let s = value.toString().split(".");
      if (s.length == 1) {
        value1 = value.toString() + ".00";
        return value1;
      }
      if (s.length > 1) {
        if (s[1].length < 2) {
          value1 = value.toString() + "0";
        }
        return value1;
      }
    },

    cancel(e) {
      if (this.checkedOrderType === e) {
        this.checkedOrderType = null
      } else {
        this.checkedOrderType = e
      }
      this.getGoodsList()
    },
    cancel1(e) {
      if (this.tag === e) {
        this.tag = null
      } else {
        this.tag = e
      }
      this.getGoodsList()
    }
  },
  mounted() {
    this.getfenlei();
  },
  computed: {
    summaryCategories: function () {
      let arr = []
      this.categories.foreach(item => {
        item.children.foreach(item => {
          arr.push(item)
        })
      });
      return arr;
    }
  }
};
</script>
<style scoped>
.wrapper {
  width: 1280px;
  margin: 0 auto 64px;
}

::v-deep .el-breadcrumb__inner.is-link:hover {
  color: #000;
}

.bread {
  cursor: pointer;
  position: absolute;
  top: -48px;
  left: 0%;
}

.main {
  margin-top: 30px;
  display: flex;
}

.left {
  flex: 1;
  position: relative;
}

.leftbox {
  margin-bottom: 20px;
}

.leftbox p {
  cursor: pointer;
}

::v-deep .el-button--primary {
  background-color: #959595;
  border-color: #959595;
}

.active {
  box-shadow: none !important;
  /* 去除阴影效果 */
  border: 3px solid #ccc;
  border-radius: 5px;
}


.right {
  flex: 4;
  margin-left: 50px;
}

/*不带背景的分页按钮背景色begin*/

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #333;
  border-color: #333;
}

::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: #333;
  background: #333;
}

::v-deep .el-radio__label {
  font-size: 14px;
}

::v-deep .el-checkbox__label {
  font-size: 14px;
}

::v-deep .el-radio__input.is-checked+.el-radio__label {
  color: #333;
  font-weight: bold;
}

::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
  color: #333;
  font-weight: bold;
}

::v-deep .el-select .el-input.is-focus .el-input__inner {
  border-color: #333;
}

::v-deep .el-input__inner:focus {
  border-color: #333;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #000;
  color: #FFF;
}

/* ::v-deep .el-input-number__decrease {
  color: #FFF;
  background: #000;
} */

/* ::v-deep .el-input__inner {
  border: 1px solid #000;
} */

::v-deep .el-input-number__decrease:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled),
.el-input-number__increase:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled) {
  border-color: #dcdfe6;
}

::v-deep .el-input-number__increase:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled),
.el-input-number__increase:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled) {
  border-color: #dcdfe6;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #CA141D;
}

/* ::v-deep .el-input-number__increase {
  color: #FFF;
  background: #000;
} */

::v-deep .el-divider--vertical {
  height: 4em;
  margin: 0;
}

::v-deep .el-divider {
  background-color: #F3F3F3;
}

::v-deep .el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner {
  -webkit-box-shadow: 0 0 0px 0px transparent;
  box-shadow: 0 0 0px 0px transparent;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* 主要内容区CSS END */
.off {
  position: absolute;
  top: 0;
  left: 0;
  width: 65px;
  height: 25px;
  font-size: 11px;
  font-weight: bold;
  border-radius: 20px 0 20px 0;
  color: #FFF;
  background-color: #FF764B;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NEW {
  position: absolute;
  top: 0;
  left: 0;
  width: 65px;
  height: 25px;
  font-size: 11px;
  font-weight: bold;
  border-radius: 20px 0 20px 0;
  color: #FFF;
  background-color: #52DD79;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Purchased {
  position: absolute;
  top: 0;
  left: 45px;
  padding-left: 12px;
  width: 103px;
  height: 25px;
  font-size: 11px;
  font-weight: bold;
  border-radius: 20px 0 20px 0;
  color: #FFF;
  background-color: #0066FF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-title {
  font-size: 16px;
  margin-bottom: 12px;
}

.title {
  font-size: 14px;
  font-weight: bolder;
  margin-bottom: 11px;
}

.brand-item,
.category-item {
  display: block;
}

.amount-input {
  width: 100px;
}

.list {
  height: 110px;
  border: 1px solid #D9D9D9;
  border-radius: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  position: relative;
}

.listtit {
  font-size: 12px;
  color: #545454;
}

::v-deep .listtit .el-button {
  padding: 0;
  border: none;
}

::v-deep .listtit .el-button:hover {
  background-color: #fff;
}

.more {
  display: inline-block;
  width: 80px;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  font-weight: bold;
  background-color: #f5f7fa;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}
</style>